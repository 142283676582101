import React from 'react'
import { I18n } from '@front/volcanion/'

import { Grid, Typography, Box, StepLabel, StepContent } from '@mui/material'

import { ModelAutocompleteField } from '@front/squirtle/'

const Row1 = props =>
  <Grid container item xs={12} spacing={1} direction='row' alignItems='center'>
    <Grid item xs>
      <ModelAutocompleteField
        name={'start_zone'}
        label={I18n.t('address.source')}
        model_name={'zone'}
        labelKeys={['name']}
        searchMode={'search'}
        searchQuery={'contains'}
        autocompleteKey={'name'}
        selectFirst={false}
        loadOnMount
        required
      />
    </Grid>

  </Grid>

const GeoSection = props => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('zone.associated')}</Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ borderRadius: 3, boxShadow: 6, p: 2, mb: 1 }}>
      <Grid container>
        <Row1 />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(GeoSection)
