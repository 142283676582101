import React from 'react'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row, isSelected, ...props }) => <>
  <TableCell size={'small'}><TextCell value={_.get(row, 'name')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'flat_rate')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'km_rate')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'hour_rate')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'min_rate')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'max_rate')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'handling_rate')} /></TableCell>
</>

export default React.memo(Row)
