import React from 'react'

import { Box, Grid, Typography } from '@mui/material'
import { I18n } from '@front/volcanion'
import { DateTimeField, ModelAutocompleteField, SingleOptionSelectorField, TextField } from '@front/squirtle'
import { UserUtils } from '@front/volcanion/utils'

const Title = props =>
  <Grid item xs>
    <Typography variant='h5'>{I18n.t('evaluation.reviews')}</Typography>
  </Grid>

const Row1 = props =>
  <Grid container item sx spacing={1}>
    <Grid item xs>
      <TextField name='do.customerinfo.client_number' label={I18n.t('user.id')} />
    </Grid>
    <Grid item xs>
      <TextField name='do.info.last_name' label={I18n.t('name.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <TextField name='do.info.first_name' label={I18n.t('name.first_name', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <ModelAutocompleteField
        name={'service.contract.company'}
        label={I18n.t('company.label', { count: 1 })}
        model_name={'company'}
        searchMode='search'
        searchQuery={'startsWith'}
        autocompleteMutator={q => ({
          or: [{ client_id: { startsWith: q } }, { name: { startsWith: q } }]
        })}
        labelKeys={['label']}
        config={{
          mutator: company => ({ label: `${_.get(company, 'client_id')} - ${_.get(company, 'name')}`, ...company })
        }}
        loadOnFocus={false}
        debounceTime={400}
      />
    </Grid>
    <Grid item xs>
      <ModelAutocompleteField
        name={'assigned_transport.driver'}
        label={I18n.t('driver.search_multiple')}
        model_name={'user'}
        searchMode='search'
        searchQuery={'='}
        autocompleteMutator={q => ({
          or: [
            { info: { user_client_id: { startsWith: _.split(q, ' ') } } },
            { info: { first_name: { startsWith: _.split(q, ' ') } } },
            { info: { last_name: { startsWith: _.split(q, ' ') } } }]
        })}
        labelKeys={['label']}
        multiple={false}
        loadOnFocus={false}
        config={{
          forced_filter: { driverinfo: { '!': null } },
          populate: ['info', 'customerinfo'],
          mutator: user => ({
            label: UserUtils.getFormatedName({ ...user?.info, designation: user?.customerinfo?.name }, { prefix: user?.info?.user_client_id }),
            ...user
          })
        }}
        selectSingleOption
        debounceTime={400}
      />
    </Grid>
  </Grid>

const Row2 = props =>
  <Grid container item sx spacing={1}>
    <Grid item xs={3}>
      <DateTimeField
        name='requestedAt_start'
        dateProps={{
          label: I18n.t('date.search'),
          inputFormat: 'DD/MM/YYYY',
        }}
        timeProps={{
          label: I18n.t('prefix.at'),
          minTime: null
        }}
        default_time='00:00'
      />
    </Grid>
    <Grid item xs={3}>
      <DateTimeField
        name='requestedAt_end'
        dateProps={{
          label: I18n.t('prefix.to'),
          inputFormat: 'DD/MM/YYYY'
        }}
        timeProps={{
          label: I18n.t('prefix.at'),
          minTime: null
        }}
        default_time='23:59'
      />
    </Grid>
    <Grid item xs>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', pl: 3 }}>
        <Typography variant='subtitle1' sx={{ mr: 1, width: '-webkit-fill-available' }}>{I18n.t('evaluation.interval')}</Typography>
        <TextField name='average_rating_start' type='number' />
        <Typography variant='subtitle1' sx={{ ml: 1, mr: 1 }}>{I18n.t('prefix.and')}</Typography>
        <TextField name='average_rating_end' type='number' />
        <Typography variant='subtitle1' sx={{ ml: 1, mr: 1 }}>{'/5'}</Typography>
      </Box>
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField name='with_comment' label={I18n.t('order.with_comment')} />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Box sx={{ boxShadow: 3, borderRadius: 1, p: 2 }}>
    <Grid container rowSpacing={3}>
      <Title />
      <Row1 />
      <Row2 />
    </Grid>
  </Box>

export default React.memo(SearchForm)
